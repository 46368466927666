import Dialog from '@/components/Dialog.vue';
import type { App } from 'vue';

/**
 * register global components
 * @param app Vue app instance
 * app.component(<component>, Component);
 * update components.d.ts when adding new components
 */
export function registerComponents(app: App) {
  app.component('DialogBase', Dialog);
}
