import { useLocalStorage } from '@vueuse/core';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTheme } from 'vuetify';

export function useThemeSetting() {
  const { t } = useI18n();
  const theme = useTheme();
  const themeStorage = useLocalStorage('theme', theme.global.name.value);

  const backgroundColors = computed(() => {
    return theme.current.value.dark ? 'theme-background' : 'theme-surface';
  });

  const invertedBackgroundColors = computed(() => {
    return theme.current.value.dark ? 'theme-surface' : 'theme-background';
  });

  const dividerClasses = computed(() =>
    theme.current.value.dark ? 'tw-opacity-10' : 'tw-opacity-100'
  );

  const themes = computed(() => [
    { title: t('PI5.theme_light'), value: 'light' },
    { title: t('PI5.theme_dark'), value: 'dark' }
  ]);

  const invertedTheme = computed(() => {
    return theme.current.value.dark ? 'light' : 'dark';
  });

  function changeTheme(newTheme: string) {
    theme.global.name.value = newTheme;
    themeStorage.value = theme.global.name.value;
  }

  return {
    backgroundColors,
    invertedBackgroundColors,
    dividerClasses,
    changeTheme,
    theme,
    invertedTheme,
    themes
  };
}
