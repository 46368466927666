<template>
  <v-navigation-drawer theme="dark" rail-width="70" width="192" mobile-breakpoint="sm" :rail="rail">
    <v-list class="tw-mb-2">
      <v-list-item>
        <v-list-item-title class="tw-ml-2">
          <span>PI</span>
          <span class="tw-align-super tw-text-sm">5</span>
        </v-list-item-title>
        <template #prepend>
          <img class="tw-size-10 tw-rounded-full" src="@/assets/icons/logo.svg" alt="Logo" />
        </template>
        <template v-if="!rail" #append>
          <v-btn
            variant="text"
            icon
            @click.stop.prevent="rail = !rail"
            data-testid="sidebarCollapse"
          >
            <WindowIcon class="tw-size-5 -tw-rotate-90" />
          </v-btn>
        </template>
      </v-list-item>
    </v-list>

    <ul>
      <li
        v-for="(item, index) in navItems"
        :key="item.title"
        :class="['tw-px-2', { 'tw-mb-4': index === 1, 'tw-py-1': rail }]"
      >
        <v-hover>
          <template #default="{ isHovering, props }">
            <RouterLink
              v-bind="props"
              :data-testid="item.title"
              :to="{ path: `/${item.value}` }"
              :class="[
                'tw-flex tw-items-center tw-gap-2',
                {
                  'tw-justify-center': rail,
                  'router-link-exact-active text-primary':
                    router.currentRoute.value.name === item.value
                }
              ]"
              active-class="text-primary"
            >
              <span
                v-if="typeof item.icon === 'string'"
                v-html="item.icon"
                :class="[
                  isHovering ? 'text-primary' : 'text-default',
                  'tw-inline-block tw-p-1 navIcon'
                ]"
              />
              <span v-else class="tw-p-1">
                <component
                  :is="item.icon"
                  :class="[isHovering ? 'text-primary' : 'text-default', 'tw-size-7']"
                />
              </span>
              <span v-if="!rail" :class="['tw-font-medium', { 'text-primary': isHovering }]">
                {{ item.title }}
              </span>
              <v-tooltip v-if="rail" activator="parent" location="right" offset="0">
                <span class="tw-font-medium"> {{ item.title }} </span>
              </v-tooltip>
            </RouterLink>
          </template>
        </v-hover>
      </li>
      <li class="tw-px-2 tw-py-1">
        <v-hover>
          <template #default="{ isHovering, props }">
            <a
              v-bind="props"
              :href="microsoftTeamsDeepLink"
              :class="['tw-flex tw-items-center tw-gap-3', { 'tw-justify-center': rail }]"
            >
              <span v-html="MicrosoftTeamsIcon" class="tw-text-2xl tw-m-1"></span>
              <span v-if="!rail" :class="['tw-font-medium', { 'text-primary': isHovering }]">
                Chat
              </span>
            </a>
            <v-tooltip v-if="rail" activator="parent" location="right" offset="0">
              <span class="tw-font-medium">Chat</span>
            </v-tooltip>
          </template>
        </v-hover>
      </li>
    </ul>
  </v-navigation-drawer>

  <v-navigation-drawer v-model="mobileNavDrawer" location="right" temporary>
    <div class="tw-relative tw-pt-8">
      <v-list>
        <v-list-item v-for="item in navItems" :key="item.title" @click="mobileNavDrawer = false">
          <v-list-item-title class="tw-flex tw-gap-4">
            <template v-if="typeof item.icon === 'string'">
              <span v-html="item.icon" class="navIcon" />
            </template>
            <component v-else :is="item.icon" :class="['tw-size-7']" />
            <RouterLink :to="{ path: `/${item.value}` }">
              {{ item.title }}
            </RouterLink>
          </v-list-item-title>
        </v-list-item>
        <v-list-item :href="microsoftTeamsDeepLink">
          <v-list-item-title class="tw-flex tw-gap-4 tw-items-center">
            <span v-html="MicrosoftTeamsIcon" class="tw-text-xl tw-m-1"></span>
            Chat
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-btn
        @click="mobileNavDrawer = false"
        position="absolute"
        density="comfortable"
        class="tw-top-2 tw-right-2"
        flat
        icon
      >
        <XMarkIcon class="tw-size-6" />
      </v-btn>
    </div>
  </v-navigation-drawer>

  <v-app-bar scroll-behavior="elevate" density="default" class="tw-border-b">
    <slot name="app-bar">
      <v-app-bar-nav-icon v-if="rail && smAndUp" @click="rail = !rail" data-testid="sidebarExpand">
        <WindowIcon class="tw-size-5 -tw-rotate-90" />
      </v-app-bar-nav-icon>
      <div class="tw-flex tw-items-center tw-gap-4 tw-w-full">
        <h1 class="tw-capitalize tw-text-lg tw-mx-4">
          {{ router.currentRoute.value.meta?.title || router.currentRoute.value.name }}
        </h1>
        <MainSearch
          class="tw-w-full lg:tw-w-1/3 tw-mx-auto"
          :key="router.currentRoute.value.fullPath"
        />
      </div>
    </slot>

    <template #append>
      <v-btn variant="tonal" rounded class="tw-mr-2" data-testid="accountMenuButton">
        <span v-show="smAndUp" class="tw-normal-case">
          {{ accountStore.displayName }}
        </span>
        <v-avatar v-if="accountStore.me?.photoUrl" style="right: -14px">
          <v-img :alt="accountStore.displayName" :src="accountStore.me.photoUrl"></v-img>
        </v-avatar>
        <UserCircleIcon v-else class="tw-size-6 tw-relative -tw-right-2" />
        <v-menu activator="parent">
          <v-list class="tw-p-0">
            <v-list-subheader class="tw-pt-2" @click.stop> Logged in as </v-list-subheader>
            <p class="tw-px-4" @click.stop>{{ accountStore.user?.attributes?.email }}</p>
            <v-divider :class="['tw-my-2', dividerClasses]" />
            <!--  -->
            <v-list-subheader class="tw-min-h-6" @click.stop>
              <span class="tw-inline-flex">
                <span class="tw-mr-2">{{ t(`PI5.language`) }}</span>
                <GlobeAltIcon class="tw-size-5" />
              </span>
            </v-list-subheader>
            <v-list-item>
              <v-list-item-title @click.stop>
                <LanguageSelector
                  :list-props="{ density: 'compact' }"
                  class="tw-m-1"
                  hide-details
                />
              </v-list-item-title>
            </v-list-item>

            <v-list-subheader class="tw-min-h-6 tw-pt-2" @click.stop>
              <span class="tw-inline-flex">
                <span class="tw-mr-2">{{ t(`PI5.theme`) }}</span>
                <SunIcon v-if="!theme.current.value.dark" class="tw-size-5" />
                <MoonIcon v-else class="tw-size-5" />
              </span>
            </v-list-subheader>
            <v-list-item class="tw-mb-4">
              <v-btn-toggle
                :modelValue="theme.global.name.value"
                @update:modelValue="changeTheme"
                variant="outlined"
                mandatory
              >
                <v-btn
                  v-for="themeItem in themes"
                  :key="themeItem.value"
                  :value="themeItem.value"
                  @click.stop
                >
                  {{ themeItem.title }}
                </v-btn>
              </v-btn-toggle>
            </v-list-item>

            <v-divider :class="dividerClasses"></v-divider>

            <v-list-item :to="{ name: 'settings-account' }" data-testid="accountButton">
              <template #prepend>
                <UserCircleIcon class="tw-mr-4 tw-size-5" />
              </template>
              <v-list-item-title>{{ t(`PI5.account`) }}</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ name: 'settings-general' }">
              <template #prepend>
                <Cog6ToothIcon class="tw-mr-4 tw-size-5" />
              </template>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item>

            <v-list-item @click="logout" data-testid="logoutButton">
              <template #prepend>
                <ArrowRightStartOnRectangleIcon class="tw-mr-4 tw-size-5" />
              </template>
              <v-list-item-title>{{ t(`PI5['account.logout']`) }}</v-list-item-title>
            </v-list-item>

            <v-divider :class="dividerClasses"></v-divider>

            <v-list-subheader @click.stop class="tw-text-xs">
              <span class="tw-inline-flex tw-items-center">
                <span class="tw-mr-3"> VER. </span>
                {{ appVersion }}
              </span>
            </v-list-subheader>
          </v-list>
        </v-menu>
      </v-btn>
      <v-app-bar-nav-icon v-if="!smAndUp" @click="mobileNavDrawer = !mobileNavDrawer" />
    </template>
  </v-app-bar>

  <v-main class="tw-flex tw-flex-col tw-h-screen tw-transition-none">
    <slot />
  </v-main>
</template>

<script lang="ts" setup>
import { computed, ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';
import { useLocalStorage } from '@vueuse/core';
import { useThemeSetting } from '@/composables/useThemeSetting';
import LanguageSelector from '@/components/Layout/components/LanguageSelector.vue';
import MainSearch from '@/components/MainSearch.vue';
import { useAccountStore } from '@/stores/account';
import DashboardIcon from '@/assets/icons/dashboard.svg?raw';
import NewsIcon from '@/assets/icons/news.svg?raw';
import stockOrderIcon from '@/assets/icons/stock-order.svg?raw';
import StockDeliveryIcon from '@/assets/icons/stock-delivery.svg?raw';
import ServiceIcon from '@/assets/icons/service.svg?raw';
import OffersIcon from '@/assets/icons/offers.svg?raw';
import CapacityIcon from '@/assets/icons/capacity.svg?raw';
import MicrosoftTeamsIcon from '@/assets/icons/microsoft-teams.svg?raw';
import {
  ArrowPathIcon,
  ArrowRightStartOnRectangleIcon,
  SunIcon,
  MoonIcon,
  CheckCircleIcon,
  Cog6ToothIcon,
  GlobeAltIcon,
  UserCircleIcon,
  XMarkIcon
} from '@heroicons/vue/24/outline';
import { WindowIcon } from '@heroicons/vue/24/solid';
import { useAuth } from '@/composables/useAuth';
import { useI18n } from 'vue-i18n';

const microsoftTeamsDeepLink = 'msteams://teams.microsoft.com/l/team';
const appVersion = __APP_VERSION__;
const { t } = useI18n();
const router = useRouter();
const { smAndUp } = useDisplay();
const sideBarStorage = useLocalStorage('sideBar', true);
const accountStore = useAccountStore();
const { themes, changeTheme, theme, dividerClasses } = useThemeSetting();
const { logout } = useAuth();
const mobileNavDrawer = ref(false);
const rail = ref(sideBarStorage.value);

const navItems = computed(() => [
  { title: t('PI5.dashboard'), icon: DashboardIcon, value: '' },
  { title: t('PI5.news'), icon: NewsIcon, value: 'news' },
  { title: t('PI5.orders'), icon: CheckCircleIcon, value: 'orders' },
  { title: t('PI5.quotes'), icon: OffersIcon, value: 'quotes' },
  { title: 'Stock order', icon: stockOrderIcon, value: 'stock-order' },
  { title: 'Credit note', icon: ArrowPathIcon, value: 'credit-note' },
  { title: 'Stock delivery', icon: StockDeliveryIcon, value: 'stock-delivery' },
  { title: 'Service', icon: ServiceIcon, value: 'service' },
  { title: t('PI5.capacity'), icon: CapacityIcon, value: 'capacity' }
]);

watchEffect(() => {
  if (smAndUp.value) mobileNavDrawer.value = false;
});

watchEffect(() => {
  sideBarStorage.value = rail.value;
});
</script>

<style lang="scss" scoped>
.navIcon :deep(svg) {
  width: 28px;
  height: 28px;

  path {
    stroke: currentColor;
  }
}
</style>
