import type { RouteLocation } from 'vue-router';

export default {
  path: '/quotes/:orderId?',
  name: 'quotes',
  meta: {
    title: 'Quotes',
    stringGroups: ['DocumentList'],
    orderType: 'QUOTE'
  },
  props: (route: RouteLocation) => ({
    orderId: route.params.orderId,
    orderType: 'QUOTE'
  }),
  component: () => import('@/views/orders/orders.vue')
};
